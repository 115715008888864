<template>
  <div class="drawer" :class="{ 'is-active': isOpen }">
    <div
      class="drawer__content"
      :style="maxWidth ? `max-width: ${maxWidth};` : 'max-width: 1164px'"
    >
      <div v-if="title" class="drawer__header">
        <span>{{ title }}</span>

        <c-button-icon icon="fas fa-times" fab @click="close" />
      </div>

      <div class="drawer__slot">
        <slot />
      </div>
    </div>

    <div class="drawer__backdrop" @click="close" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  CLOSE_ANIMATION_DURATION,
  CLOSE_ANIMATION_DURATION_IN_MS,
} from "components/wrappers/drawer/drawerHelper";
import { HEADER_HEIGHT_PX } from "common/UIConstants";

const ANIMATION_DELAY_TIME_IN_MS = 10;

// Router
const router = useRouter();
const route = useRoute();

// Emits
const emit = defineEmits(["close"]);

// Props
const props = defineProps({
  maxWidth: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  modelId: {
    type: [String, Number],
    required: false,
    default: () => null,
  },
});

// Refs
const isOpen = ref(false);

// Methods
const close = async () => {
  isOpen.value = false;

  if (route.query?.sidebar) {
    const { sidebar, id, ...query } = route.query;

    router.push({ query });
  }

  await new Promise((resolve) => setTimeout(resolve, CLOSE_ANIMATION_DURATION));
  emit("close");
};

defineExpose({ close });

onMounted(async () => {
  if (props.name) {
    router.push({
      query: {
        ...route.query,
        ...{ sidebar: props.name, ...(props.modelId && { id: props.modelId }) },
      },
    });
  }

  // Set a timer to prevent the drawer from opening too quickly and the animation won't work
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ANIMATION_DELAY_TIME_IN_MS);
  });

  isOpen.value = true;
});
</script>

<style lang="scss">
.drawer {
  &__content {
    @apply tw-fixed tw-right-0 tw-top-0 tw-z-[9998] tw-h-dvh tw-w-11/12 tw-overflow-y-scroll tw-overscroll-contain tw-bg-white xl:tw-w-9/12;
    transform: translateX(100%);
    transition: transform v-bind(CLOSE_ANIMATION_DURATION_IN_MS) ease;
  }

  &__header {
    @apply tw-sticky tw-top-0 tw-z-50 tw-flex tw-items-center tw-justify-between tw-bg-primary tw-px-6 tw-py-3 tw-text-white;
  }

  &__backdrop {
    @apply tw-pointer-events-none tw-fixed tw-inset-0 tw-z-[9997] tw-cursor-pointer tw-bg-black tw-opacity-0;
    transition: opacity v-bind(CLOSE_ANIMATION_DURATION_IN_MS) ease;
  }

  &__slot {
    @apply tw-h-screen tw-overflow-y-scroll;
    padding-bottom: v-bind(HEADER_HEIGHT_PX);
  }

  &.is-active & {
    &__content {
      transform: translateX(0);
    }

    &__backdrop {
      pointer-events: auto;
      opacity: 0.5;
    }
  }
}
</style>
