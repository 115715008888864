import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";
import useHelpers from "composables/useHelpers.js";
import { shouldFetch } from "common/fetchHelpers.js";
import axios from "axios";

/**
 * Overview Store
 *
 * This store manages the state and actions related to group meetings and their filters.
 */
export const useOverviewStore = defineStore("overview", () => {
  // Refs
  const group_meetings = ref([]);
  const group_meeting_filters = ref([]);
  const learning_objectives = ref([]);
  const learning_objectives_filters = ref([]);
  const overview_learning_objectives_users = ref([]);
  const overviewPortfolioFilters = ref([]);
  const overviewPortfolios = ref([]);

  const isFetching = ref({
    group_meetings: false,
    learning_objectives: false,
    group_meeting_filters: false,
    learning_objectives_filters: false,
    overview_learning_objectives_users: false,
    overview_portfolio_filters: false,
    overview_portfolios: false,
  });

  const hasFetched = ref({
    group_meetings: false,
    learning_objectives: false,
    group_meeting_filters: false,
    learning_objectives_filters: false,
    overview_learning_objectives_users: false,
    overview_portfolio_filters: false,
    overview_portfolios: false,
  });

  // Methods
  /**
   * Fetches group meetings.
   * @param {Object} options - The options for fetching group meetings.
   * @param {boolean} [options.forceFetch=false] - Whether to force fetch the data.
   * @param {Array<number>} [options.meetingIds=[]] - The IDs of the meetings to fetch.
   * @returns {Promise<Array<Object>>} The fetched group meetings.
   */
  const fetchGroupMeetings = async ({
    forceFetch = false,
    meetingIds = [],
  } = {}) => {
    if (!shouldFetch(hasFetched.value["group_meetings"]) && !forceFetch) {
      return group_meetings.value || [];
    }

    const { schoolId } = useHelpers();

    isFetching.value["group_meetings"] = true;

    try {
      const { data: fetchedData } = await axios.get(
        `/api/school/${schoolId}/group-meetings/overview`,
        {
          params: {
            "group_meetings[]": meetingIds,
          },
        },
      );

      hasFetched.value["group_meetings"] = new Date();

      group_meetings.value = fetchedData.data;

      return group_meetings.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["group_meetings"] = false;
    }
  };

  /**
   * Fetches group meeting filters.
   * @param {Object} options - The options for fetching group meeting filters.
   * @param {boolean} [options.forceFetch=false] - Whether to force fetch the data.
   * @returns {Promise<Array<Object>>} The fetched group meeting filters.
   */
  const fetchGroupMeetingFilters = async ({ forceFetch = false } = {}) => {
    if (
      !shouldFetch(hasFetched.value["group_meeting_filters"]) &&
      !forceFetch
    ) {
      return group_meeting_filters.value || [];
    }

    const { schoolId } = useHelpers();

    isFetching.value["group_meeting_filters"] = true;

    try {
      const { data: fetchedData } = await axios.get(
        `/api/school/${schoolId}/group-meetings/overview/filters`,
      );

      hasFetched.value["group_meeting_filters"] = new Date();

      group_meeting_filters.value = fetchedData.data;

      return group_meeting_filters.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["group_meeting_filters"] = false;
    }
  };

  /**
   * Fetches learning objectives filters.
   * @param {Object} options - The options for fetching learning objectives filters.
   * @param {boolean} [options.forceFetch=false] - Whether to force fetch the data.
   * @returns {Promise<Array<Object>>} The fetched learning objectives filters.
   */
  const fetchLearningObjectivesFilters = async ({
    forceFetch = false,
  } = {}) => {
    if (
      !shouldFetch(hasFetched.value["learning_objectives_filters"]) &&
      !forceFetch
    ) {
      return learning_objectives_filters.value || [];
    }

    const { schoolId } = useHelpers();

    isFetching.value["learning_objectives_filters"] = true;

    try {
      const { data: fetchedData } = await axios.get(
        `/api/school/${schoolId}/learning-objectives/overview/filters`,
      );

      hasFetched.value["learning_objectives_filters"] = new Date();

      learning_objectives_filters.value = fetchedData.data;

      return learning_objectives_filters.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["learning_objectives_filters"] = false;
    }
  };

  const fetchLearningObjectivesUsers = async ({
    forceFetch = false,
    filters = {},
  } = {}) => {
    if (
      !shouldFetch(hasFetched.value["overview_learning_objectives_users"]) &&
      !forceFetch
    ) {
      return overview_learning_objectives_users.value || [];
    }

    const { schoolId } = useHelpers();

    isFetching.value["overview_learning_objectives_users"] = true;

    try {
      const { data: fetchedData } = await axios.get(
        `/api/school/${schoolId}/learning-objectives/overview`,
        {
          params: {
            ...(Object.keys(filters).length && filters),
          },
        },
      );

      hasFetched.value["overview_learning_objectives_users"] = new Date();

      overview_learning_objectives_users.value = fetchedData.data;

      return overview_learning_objectives_users.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        overview_learning_objectives_users.value = [];

        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["overview_learning_objectives_users"] = false;
    }
  };

  const fetchOverviewLearningObjectivesSingleStudent = async ({
    forceFetch = false,
    args = {},
    filters = {},
  } = {}) => {
    const { studentId, lessonClass } = args;
    const { schoolId } = useHelpers();

    if (!studentId || !lessonClass || !schoolId) return;

    const foundSubject = overview_learning_objectives_users.value
      .find((student) => student.user.id === studentId)
      ?.subjects.find((subject) => subject.lesson_class === lessonClass);

    if (!foundSubject) return;

    if (foundSubject.clusters !== undefined && !forceFetch) return;

    try {
      const { data: fetchedClusters } = await axios.get(
        `/api/school/${schoolId}/learning-objectives/overview/${studentId}/${lessonClass}`,
        {
          params: {
            ...(Object.keys(filters).length && filters),
          },
        },
      );

      foundSubject.clusters = fetchedClusters;

      return fetchedClusters;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    }
  };

  const fetchOverviewPortfolioFilters = async ({ forceFetch = false } = {}) => {
    const { schoolId } = useHelpers();

    if (isFetching.value["overview_portfolio_filters"]) return;

    if (
      !shouldFetch(hasFetched.value["overview_portfolio_filters"]) &&
      !forceFetch
    ) {
      return overviewPortfolioFilters.value || [];
    }

    try {
      const { data: fetchedData } = await axios.get(
        `/api/school/${schoolId}/portfolio/overview/filters`,
      );

      hasFetched.value["overview_portfolio_filters"] = new Date();
      overviewPortfolioFilters.value = fetchedData.data;

      return overviewPortfolioFilters.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["overview_portfolio_filters"] = false;
    }
  };

  const fetchOverviewPortfolios = async ({
    forceFetch = false,
    filters = {},
  } = {}) => {
    const { schoolId } = useHelpers();

    if (!shouldFetch(hasFetched.value["overview_portfolios"]) && !forceFetch) {
      return overviewPortfolios.value || [];
    }

    try {
      const { data: fetchedData } = await axios.get(
        `/api/school/${schoolId}/portfolio/overview`,
        {
          params: {
            ...(Object.keys(filters).length && filters),
          },
        },
      );

      hasFetched.value["overview_portfolios"] = new Date();
      overviewPortfolios.value = fetchedData.data;

      return overviewPortfolios.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        overviewPortfolios.value = [];
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      isFetching.value["overview_portfolios"] = false;
    }
  };

  return {
    group_meetings,
    group_meeting_filters,
    learning_objectives_filters,
    overview_learning_objectives_users,
    overviewPortfolioFilters,
    overviewPortfolios,
    fetchGroupMeetings,
    fetchGroupMeetingFilters,
    fetchLearningObjectivesFilters,
    fetchLearningObjectivesUsers,
    fetchOverviewLearningObjectivesSingleStudent,
    fetchOverviewPortfolioFilters,
    fetchOverviewPortfolios,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOverviewStore, import.meta.hot));
}
