<template>
  <q-expansion-item
    v-bind="$attrs"
    class="expansion-item"
    :model-value="isExpanded"
    ref="expansionItemRef"
  >
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-expansion-item>
</template>

<script setup>
import { inject, onMounted, provide, ref, useTemplateRef, watch } from "vue";

const expansionItemRef = useTemplateRef("expansionItemRef");

defineExpose({
  expansionItemRef,
});

const props = defineProps({
  expandAll: {
    type: Boolean,
    default: false,
  },
});

const isExpanded = ref(false);

// Register itself in parent
const children = ref([]);
const registerChild = (child) => {
  children.value.push(child);
};

const expandAllChildren = (state) => {
  isExpanded.value = state;

  children.value.forEach((child) => {
    child.expandAllChildren(state);
  });
};

// If this ExpansionItem is inside another ExpansionItem, register it
const parentRegister = inject("registerChild", null);

watch(
  () => props.expandAll,
  (value) => {
    isExpanded.value = value;

    expandAllChildren(value);
  },
);

// Provide expand function & registration
provide("registerChild", registerChild);
provide("expandAllChildren", expandAllChildren);

onMounted(() => {
  if (parentRegister) {
    parentRegister({ expandAllChildren });
  }
});
</script>

<style lang="scss">
.q-expansion-item {
  .q-item.q-item-type:has(.q-expansion-item__toggle-focus) {
    &:hover {
      @apply tw-bg-slate-200;
    }
  }
}
</style>
